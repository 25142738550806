import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import React, { useState } from "react";

import { Divider } from 'primereact/divider';
import { ImageViewer } from 'components/ImageViewer';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import InfractiondetailInnerdetaillistPage from 'pages/infractiondetail/Innerdetaillist'

import InfractiondetailInnerdetaillistDocumentPage from 'pages/infractiondetail/Innerdetaillistdocumentos';
import useApp from 'hooks/useApp';
import { TabView, TabPanel } from 'primereact/tabview';
import useEditPage from 'hooks/useEditPage';
const InfractionEditPage = (props) => {
        const app = useApp();
    // form validation schema
    const validationSchema = yup.object().shape({
        code: yup.string().nullable().label("Code"),
        description: yup.string().nullable().label("Description"),
        dsm: yup.number().nullable().label("Dsm"),
        retain: yup.string().nullable().label("Retain")
    });

 const AltaBaja = [
        { name: 'Alta', value: 1 },
        { name: 'Baja', value: 0 }
    ];
    // form default values
    const formDefaultValues = {
        code: "NULL", 
        description: '', 
        dsm: "NULL", 
        retain: "NULL", 
    }
    //where page logics resides
    const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
    //destructure and grab what we need
    const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController



const [selectedCity, setSelectedCity] = useState(null);



    //Event raised on form submit success
    function afterSubmit(response){
        app.flashMsg(props.msgTitle, props.msgAfterSave);
        if(app.isDialogOpen()){
            app.closeDialogs(); // if page is open as dialog, close dialog
        }
        else if(props.redirect) {
            app.navigate(`/infraction`);
        }
    }
    // loading form data from api
    if(loading){
        return (
            <div className="p-3 text-center">
                <ProgressSpinner style={{width:'50px', height:'50px'}} />
            </div>
        );
    }
    //display error page 
    if(apiRequestError){
        return (
            <PageRequestError error={apiRequestError} />
        );
    }
    //page is ready when formdata loaded successfully
    if(pageReady){
        return (
<main id="InfractionEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
         <div className="mb-3 grid ">
                            
                            
                        </div>
            <div className="grid ">
                <div className="md:col-12 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={
                                {
                                  id: formData.id,
                                  location: formData.location,
                                  driver_firstname: formData.driver_firstname,
                                  driver_lastname: formData.driver_lastname,
                                  driver_address: formData.driver_address,
                                  driver_email: formData.driver_email,
                                  driver_phone: formData.driver_phone,
                                  driver_license_number: formData.driver_license_number,
                                  description: formData.description,
                                  vehicle_plates: formData.vehicle_plates,
                                  vehicle_card: formData.vehicle_card,
                                  vehicle_vin: formData.vehicle_vin,
                                  owner_firstname: formData.owner_firstname,
                                  owner_lastname: formData.owner_lastname,
                                  owner_address: formData.owner_address,
                                  vehicle_model: formData.vehicle_model,
                                  vehicles_motor_type: formData.vehicles_motor_type,
                                  vehicle_type: formData.vehicle_type,
                                  vehicle_brand: formData.vehicle_brand,
                                  vehicle_line: formData.vehicle_line   
                                }
                                }
                            validationSchema={validationSchema} 
                            onSubmit={(values, actions) => {
                            submitForm(values);
                            }
                            }
                            >
                            { (formik) => {

                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                   <TabView className="col-12 md:col-12" >
                                <TabPanel header="General"  >
                                    <div className="mb-12 grid ">
                                       
                                        <div className="col-2 md:col-2">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Folio</div>

                                                    <div className="font-bold">{ formData.serie+'-'+formData.folio }</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-2 md:col-2">
                                           
                                        </div>
                                        <div className="col-2 md:col-2">
                                           
                                        </div>
                                        <div className="col-3 md:col-3"></div>
                                        <div className="col-3 md:col-3">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Fecha Infracción</div>
                                                    <div className="font-bold">{ formData.date }</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 md:col-5">
                                                <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                    <div className="">
                                                        <div className="text-400 font-medium mb-1">Descripción</div>
                                                        <InputTextarea name="description"  onChange={formik.handleChange}  value={formik.values.description}   label="description" type="text" placeholder="Descripcion"        className={inputClassName(formik?.errors?.description)} ></InputTextarea>
                                                         <ErrorMessage name="description" component="span" className="p-error" />
                                                    </div>
                                                </div>
                                        </div>
                                         <div className="col-3 md:col-3">
                                           
                                        </div>
                                        
                                        
                                        <div className="col-4 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Ubicación de Infracción</div>
                                                    <InputTextarea name="location"  onChange={formik.handleChange}  value={formik.values.location}   label="location" type="text" placeholder="Escribir ubicación"        className={inputClassName(formik?.errors?.location)} ></InputTextarea>
                                                    <ErrorMessage name="location" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        
                                  

                                        <div className="col-3 md:col-3">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Nombre Del Conductor</div>
                                                     <InputText name="driver_firstname"  onChange={formik.handleChange}  value={formik.values.driver_firstname}   label="driver_firstname" type="text" placeholder="Escribir Fecha"        className={inputClassName(formik?.errors?.date)} />
                                                    <ErrorMessage name="driver_firstname" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 md:col-3">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Apellidos del Conductor</div>
                                                     <InputText name="driver_lastname"  onChange={formik.handleChange}  value={formik.values.driver_lastname}   label="driver_lastname" type="text" placeholder=""        className={inputClassName(formik?.errors?.driver_lastname)} />
                                                  <ErrorMessage name="driver_lastname" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2 md:col-2"></div>
                                        <div className="col-4 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Dirección de Conductor</div>
                                                     <InputText name="driver_address"  onChange={formik.handleChange}  value={formik.values.driver_address}   label="driver_address" type="text"        className={inputClassName(formik?.errors?.driver_address)} />
                                                    <ErrorMessage name="driver_address" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Email del Conductor</div>
                                                    <InputText name="driver_email"  onChange={formik.handleChange}  value={formik.values.driver_email}   label="driver_email" type="email"        className={inputClassName(formik?.errors?.driver_email)} />
                                                    <ErrorMessage name="driver_email" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Teléfono del conductor</div>
                                                     <InputText name="driver_phone"  onChange={formik.handleChange}  value={formik.values.driver_phone}   label="driver_phone" type="tel"        className={inputClassName(formik?.errors?.driver_phone)} />
                                                    <ErrorMessage name="driver_phone" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Número de licencia de conducir</div>
                                                    <InputText name="driver_license_number"  onChange={formik.handleChange}  value={formik.values.driver_license_number}   label="driver_license_number" type="text"        className={inputClassName(formik?.errors?.driver_license_number)} />
                                                    <ErrorMessage name="driver_license_number" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                            </TabPanel>                            
                            <TabPanel header="Vehiculo"  >
                                <div className="mb-12 grid ">
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Placas de vehículos</div>
                                                    <InputText name="vehicle_plates"  onChange={formik.handleChange}  value={formik.values.vehicle_plates}   label="vehicle_plates" type="text"        className={inputClassName(formik?.errors?.vehicle_plates)} />
                                                    <ErrorMessage name="vehicle_plates" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Tipo de motor de vehículo</div>
                                                     <InputText name="vehicles_motor_type"  onChange={formik.handleChange}  value={formik.values.vehicles_motor_type}   label="vehicles_motor_type" type="text"        className={inputClassName(formik?.errors?.vehicles_motor_type)} />
                                                    <ErrorMessage name="vehicles_motor_type" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Tarjeta de vehículo</div>
                                                     <InputText name="vehicle_card"  onChange={formik.handleChange}  value={formik.values.vehicle_card}   label="vehicle_card" type="text"        className={inputClassName(formik?.errors?.vehicle_card)} />
                                                    <ErrorMessage name="vehicle_card" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Número de vehículo</div>
                                                     <InputText name="vehicle_vin"  onChange={formik.handleChange}  value={formik.values.vehicle_vin}   label="vehicle_vin" type="text"        className={inputClassName(formik?.errors?.vehicle_vin)} />
                                                    <ErrorMessage name="vehicle_vin" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Tipo de vehículo</div>
                                                     <InputText name="vehicle_type"  onChange={formik.handleChange}  value={formik.values.vehicle_type}   label="vehicle_type" type="text"        className={inputClassName(formik?.errors?.vehicle_type)} />
                                                    <ErrorMessage name="vehicle_type" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Marca de vehículo</div>
                                                     <InputText name="vehicle_brand"  onChange={formik.handleChange}  value={formik.values.vehicle_brand}   label="vehicle_brand" type="text"        className={inputClassName(formik?.errors?.vehicle_brand)} />
                                                    <ErrorMessage name="vehicle_brand" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Modelo de vehículo</div>
                                                     <InputText name="vehicle_model"  onChange={formik.handleChange}  value={formik.values.vehicle_model}   label="vehicle_model" type="text"        className={inputClassName(formik?.errors?.vehicle_model)} />
                                                    <ErrorMessage name="vehicle_model" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                <div className="">
                                                    <div className="text-400 font-medium mb-1">Linea vehículo</div>
                                                     <InputText name="vehicle_line"  onChange={formik.handleChange}  value={formik.values.vehicle_line}   label="vehicle_line" type="text"        className={inputClassName(formik?.errors?.vehicle_line)} />
                                                    <ErrorMessage name="vehicle_line" component="span" className="p-error" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>                            
                                    <TabPanel header="Propietario"  >
                                        <div className="mb-12 grid ">

                                                <div className="col-12 md:col-4">
                                                    <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                        <div className="">
                                                            <div className="text-400 font-medium mb-1">Nombre del propietario</div>
                                                             <InputText name="owner_firstname"  onChange={formik.handleChange}  value={formik.values.owner_firstname}   label="owner_firstname" type="text"        className={inputClassName(formik?.errors?.owner_firstname)} />
                                                    <ErrorMessage name="owner_firstname" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-4">
                                                    <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                        <div className="">
                                                            <div className="text-400 font-medium mb-1">Apellido del propietario</div>
                                                             <InputText name="owner_lastname"  onChange={formik.handleChange}  value={formik.values.owner_lastname}   label="owner_lastname" type="text"        className={inputClassName(formik?.errors?.owner_lastname)} />
                                                            <ErrorMessage name="owner_firstname" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-4">
                                                    <div className="card gap-3 align-items-center card shadow-none p-3 surface-100 ">
                                                        <div className="">
                                                            <div className="text-400 font-medium mb-1">Dirección del propietario</div>
                                                             <InputText name="owner_address"  onChange={formik.handleChange}  value={formik.values.owner_address}   label="owner_address" type="text"        className={inputClassName(formik?.errors?.owner_address)} />
                                                            <ErrorMessage name="owner_address" component="span" className="p-error" />
                                                        </div>
                                                    </div>
                                                </div>
                                       </div>
                                </TabPanel>                            
                                                            
                                          
                                  <TabPanel header="Infraccion"  >
                                    <div  style={{padding:'35px 30px'}}>
                                        <div className="mb-12 grid " ></div>
                                        <section className="page-section mb-3" >
                                            <div className="container-fluid">
                                                <div className="grid ">
                                                    <div className="col comp-grid" >
                                                        <div className="">
                                                            { pageReady &&
                                                            <InfractiondetailInnerdetaillistPage isSubPage  limit="10" showHeader={true} showBreadcrumbs={true} showFooter={true} paginate={true} >
                                                            </InfractiondetailInnerdetaillistPage>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                    <div  style={{padding:'35px 30px'}}>
                                        <div className="mb-12 grid " ></div>
                                        <section className="page-section mb-3" >
                                            <div className="container-fluid">
                                                <div className="grid ">
                                                    <div className="col comp-grid" >
                                                        <div className="">
                                                            { pageReady &&
                                                            <InfractiondetailInnerdetaillistDocumentPage isSubPage  limit="10" showHeader={true} showBreadcrumbs={true} showFooter={true} paginate={true} >
                                                            </InfractiondetailInnerdetaillistDocumentPage>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </TabPanel>     
                             </TabView>  
                             


                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Actualizar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </main>
        );
    }
}
InfractionEditPage.defaultProps = {
    primaryKey: 'id',
    pageName: 'infraction',
    apiPath: 'infraction/edit',
    routeName: 'InfractionEdit',
    submitButtonLabel: "Actualizar",
    formValidationError: "El formulario no es válido",
    formValidationMsg: "Por favor complete el formulario",
    msgTitle: "Actualizar registro",
    msgAfterSave: "Registro actualizado con éxito",
    msgBeforeSave: "",
    showHeader: true,
    showFooter: true,
    redirect: true,
    isSubPage: false
}
export default InfractionEditPage;
