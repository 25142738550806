import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';
import useAddPage from 'hooks/useAddPage';

import { MultiSelect } from 'hooks/extra';
const InfractiondetailAddPage = (props) => {
		const app = useApp();
	
	const multiservicio = new MultiSelect();
	//form validation rules
	const validationSchema = yup.object().shape({
	});
	
	//form default values
	const formDefaultValues = {
	}
	
	//page hook where logics resides
	const pageController =  useAddPage({ props, formDefaultValues, afterSubmit });
	
	// destructure and grab what the page needs
	const { formData, resetForm, handleSubmit, submitForm, pageReady, loading, saving, inputClassName } = pageController;
	






	/******SACAMOS LA LISTA DE UUID DE DEVICE *******/

		var devicearray=[];//CREAMOS ARREGLO DONDE GUARDAMOS LA LISTA DEL API
		const [device, setDevice] = useState(null);//CREAMOS SET Y VARIABLE PARA CONTENER LA LISTA DE LA API
		const [selectdevice, setSelectedDevice] = useState(null);//CREAMOS VARIABLES PARA EL SELECT
		const [selectdevicehi, setSelectedDevicehi] = useState(null);
		 const onDeviceChange = (e) => {//CHANGE DEL SELEECT
	        setSelectedDevice(e.value);
	        setSelectedDevicehi(e.value);
	       // formik.values.fk_item=e.value;
	        document.getElementById('fk_item').value=e.value;
	        document.getElementById('fk_item').focus();
	    }

		useEffect(() => {//MANDAMOS LLAMAR LA API DE GETDEVICE
	             multiservicio.getConceptosInfraccion().then(data => setDevice(data));
	       }, []);

		if(device){
		 	devicearray=multiservicio.getArraySelectConceptosInfraccion(device.records);
		}
	
	/******FIN *******/








	//event raised after form submit
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		resetForm();
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog

		}
		else if(props.redirect) {
			//app.navigate(`/infractiondetail`);
		}
	}
	
	// page loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	
	//page has loaded any required data and ready to render
	if(pageReady){
		return (
<main id="InfractiondetailAddPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Agregar nuevo"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik initialValues={
                        		{fk_infraction:parseInt(localStorage.getItem('pageid')),fk_item:0}
                        	}
                        	validationSchema={validationSchema} 
                        	onSubmit={(values, actions) =>{
                        		values.fk_item=selectdevice;
                        		submitForm(values);

                        	}}>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                             
                                            </div>
                                            <div className="col-12 md:col-9">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Infracción
                                                
                                                  <InputText id="fk_item" name="fk_item" onBlur={formik.handleBlur} onChange={formik.handleChange}   value={selectdevicehi} label="fk_item" type="hidden"  className={inputClassName(formik?.errors?.fk_item)} />
                                                <ErrorMessage name="fk_item" component="span" className="p-error" />
                                            </div>
                                            <div className="col-12 md:col-9">
	                                            <Dropdown 
	                                            	id="fk_item2"
	                                            	filter
	                                            	optionLabel="name" 
	                                            	optionValue="value" 
	                                            	value={selectdevice} 
	                                            	options={devicearray} 
	                                            	onChange={onDeviceChange} 
	                                            	placeholder="Selecionar para cambiar de infración" 
	                                            	className={inputClassName(formik?.errors?.fk_item)} 
	                                            	/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}
}

//page props and default values
InfractiondetailAddPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'infractiondetail',
	apiPath: 'infractiondetail/add',
	routeName: 'infractiondetailadd',
	submitButtonLabel: "Entregar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Crear registro",
	msgAfterSave: "Grabar agregado exitosamente",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default InfractiondetailAddPage;