import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

import IndexLayout from 'layouts/IndexLayout';
import MainLayout from 'layouts/MainLayout';
import AuthRoutes from 'components/AuthRoutes';
import IndexPage from 'pages/index/IndexPage';

import CataloginfractionitemsList from 'pages/cataloginfractionitems/List';
import CataloginfractionitemsView from 'pages/cataloginfractionitems/View';
import CataloginfractionitemsAdd from 'pages/cataloginfractionitems/Add';
import CataloginfractionitemsEdit from 'pages/cataloginfractionitems/Edit';

import CatalogdocumenttypesList from 'pages/catalogdocumenttypes/List';
import CatalogdocumenttypesView from 'pages/catalogdocumenttypes/View';
import CatalogdocumenttypesAdd from 'pages/catalogdocumenttypes/Add';
import CatalogdocumenttypesEdit from 'pages/catalogdocumenttypes/Edit';

import CataloginfractionstatusList from 'pages/cataloginfractionstatus/List';
import CataloginfractionstatusView from 'pages/cataloginfractionstatus/View';
import CataloginfractionstatusAdd from 'pages/cataloginfractionstatus/Add';
import CataloginfractionstatusEdit from 'pages/cataloginfractionstatus/Edit';
import CataloguserstatusList from 'pages/cataloguserstatus/List';
import CataloguserstatusView from 'pages/cataloguserstatus/View';
import CataloguserstatusAdd from 'pages/cataloguserstatus/Add';
import CataloguserstatusEdit from 'pages/cataloguserstatus/Edit';
import CatalogusertypesList from 'pages/catalogusertypes/List';
import CatalogusertypesView from 'pages/catalogusertypes/View';
import CatalogusertypesAdd from 'pages/catalogusertypes/Add';
import CatalogusertypesEdit from 'pages/catalogusertypes/Edit';
import CatalogvehiclebrandsList from 'pages/catalogvehiclebrands/List';
import CatalogvehiclebrandsView from 'pages/catalogvehiclebrands/View';
import CatalogvehiclebrandsAdd from 'pages/catalogvehiclebrands/Add';
import CatalogvehiclebrandsEdit from 'pages/catalogvehiclebrands/Edit';
import CatalogvehiclemodelsList from 'pages/catalogvehiclemodels/List';
import CatalogvehiclemodelsView from 'pages/catalogvehiclemodels/View';
import CatalogvehiclemodelsAdd from 'pages/catalogvehiclemodels/Add';
import CatalogvehiclemodelsEdit from 'pages/catalogvehiclemodels/Edit';
import CatalogvehiclemotortypesList from 'pages/catalogvehiclemotortypes/List';
import CatalogvehiclemotortypesView from 'pages/catalogvehiclemotortypes/View';
import CatalogvehiclemotortypesAdd from 'pages/catalogvehiclemotortypes/Add';
import CatalogvehiclemotortypesEdit from 'pages/catalogvehiclemotortypes/Edit';
import CatalogvehicleplatestatesList from 'pages/catalogvehicleplatestates/List';
import CatalogvehicleplatestatesView from 'pages/catalogvehicleplatestates/View';
import CatalogvehicleplatestatesAdd from 'pages/catalogvehicleplatestates/Add';
import CatalogvehicleplatestatesEdit from 'pages/catalogvehicleplatestates/Edit';
import CatalogvehicletypesList from 'pages/catalogvehicletypes/List';
import CatalogvehicletypesView from 'pages/catalogvehicletypes/View';
import CatalogvehicletypesAdd from 'pages/catalogvehicletypes/Add';
import CatalogvehicletypesEdit from 'pages/catalogvehicletypes/Edit';
import InfractionList from 'pages/infraction/List';
import ReporteKardex from 'pages/kardex/Reporte';
import InfractionView from 'pages/infraction/View';
import InfractionRecepcion from 'pages/infraction/Recepcion';
import InfractionEntregar from 'pages/infraction/Entregar';
import InfractionEdit from 'pages/infraction/Edit';
import InfractiondetailList from 'pages/infractiondetail/List';
import InfractiondetailView from 'pages/infractiondetail/View';
import InfractiondetailAdd from 'pages/infractiondetail/Add';
import InfractiondetailEdit from 'pages/infractiondetail/Edit';
import InfractiondetailInnerdetaillist from 'pages/infractiondetail/Innerdetaillist';
import InfractiondetailDocumentList from 'pages/infractiondetaildocument/List';
import InfractiondetailDocumentView from 'pages/infractiondetaildocument/View';
import InfractiondetailDocumentAdd from 'pages/infractiondetaildocument/Add';
import InfractiondetailDocumentEdit from 'pages/infractiondetaildocument/Edit';
import InfractiondetailInnerdetaillistDocument from 'pages/infractiondetaildocument/Innerdetaillistdocumentos';

import UmaList from 'pages/uma/List';
import UmaView from 'pages/uma/View';
import UmaAdd from 'pages/uma/Add';
import UmaEdit from 'pages/uma/Edit';

import UsersList from 'pages/users/List';
import UsersView from 'pages/users/View';
import UsersAdd from 'pages/users/Add';
import UsersEdit from 'pages/users/Edit';

import AccountPages from 'pages/account';
import HomePage from './pages/home/HomePage';
import IndexPages from './pages/index';
import ErrorPages from './pages/errors';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/styles/layout.scss';
const App = () => {
	const auth = useAuth();
	function DefaultPage(){
		if(!auth.isLoggedIn){
			return <IndexPage />
		}
		return <Navigate to="/home" replace />;
	}
	return (
		<Routes>
			<Route exact element={<AuthRoutes />}>
			<Route element={<MainLayout />}>
				<Route path="/home" element={<HomePage />} />
				

				{/* cataloginfractionitems pages routes */}
				<Route path="/cataloginfractionitems" element={<CataloginfractionitemsList />} />
				<Route path="/cataloginfractionitems/:fieldName/:fieldValue" element={<CataloginfractionitemsList />} />
				<Route path="/cataloginfractionitems/index/:fieldName/:fieldValue" element={<CataloginfractionitemsList />} />
				<Route path="/cataloginfractionitems/view/:pageid" element={<CataloginfractionitemsView />} />
				<Route path="/cataloginfractionitems/add" element={<CataloginfractionitemsAdd />} />
				<Route path="/cataloginfractionitems/edit/:pageid" element={<CataloginfractionitemsEdit />} />

				{/* catalogdocumenttypes pages routes */}
				<Route path="/catalogdocumenttypes" element={<CatalogdocumenttypesList />} />
				<Route path="/catalogdocumenttypes/:fieldName/:fieldValue" element={<CatalogdocumenttypesList />} />
				<Route path="/catalogdocumenttypes/index/:fieldName/:fieldValue" element={<CatalogdocumenttypesList />} />
				<Route path="/catalogdocumenttypes/view/:pageid" element={<CatalogdocumenttypesView />} />
				<Route path="/catalogdocumenttypes/add" element={<CatalogdocumenttypesAdd />} />
				<Route path="/catalogdocumenttypes/edit/:pageid" element={<CatalogdocumenttypesEdit />} />


				{/* cataloginfractionstatus pages routes */}
				<Route path="/cataloginfractionstatus" element={<CataloginfractionstatusList />} />
				<Route path="/cataloginfractionstatus/:fieldName/:fieldValue" element={<CataloginfractionstatusList />} />
				<Route path="/cataloginfractionstatus/index/:fieldName/:fieldValue" element={<CataloginfractionstatusList />} />
				<Route path="/cataloginfractionstatus/view/:pageid" element={<CataloginfractionstatusView />} />
				<Route path="/cataloginfractionstatus/add" element={<CataloginfractionstatusAdd />} />
				<Route path="/cataloginfractionstatus/edit/:pageid" element={<CataloginfractionstatusEdit />} />

				{/* cataloguserstatus pages routes */}
				<Route path="/cataloguserstatus" element={<CataloguserstatusList />} />
				<Route path="/cataloguserstatus/:fieldName/:fieldValue" element={<CataloguserstatusList />} />
				<Route path="/cataloguserstatus/index/:fieldName/:fieldValue" element={<CataloguserstatusList />} />
				<Route path="/cataloguserstatus/view/:pageid" element={<CataloguserstatusView />} />
				<Route path="/cataloguserstatus/add" element={<CataloguserstatusAdd />} />
				<Route path="/cataloguserstatus/edit/:pageid" element={<CataloguserstatusEdit />} />

				{/* catalogusertypes pages routes */}
				<Route path="/catalogusertypes" element={<CatalogusertypesList />} />
				<Route path="/catalogusertypes/:fieldName/:fieldValue" element={<CatalogusertypesList />} />
				<Route path="/catalogusertypes/index/:fieldName/:fieldValue" element={<CatalogusertypesList />} />
				<Route path="/catalogusertypes/view/:pageid" element={<CatalogusertypesView />} />
				<Route path="/catalogusertypes/add" element={<CatalogusertypesAdd />} />
				<Route path="/catalogusertypes/edit/:pageid" element={<CatalogusertypesEdit />} />

				{/* catalogvehiclebrands pages routes */}
				<Route path="/catalogvehiclebrands" element={<CatalogvehiclebrandsList />} />
				<Route path="/catalogvehiclebrands/:fieldName/:fieldValue" element={<CatalogvehiclebrandsList />} />
				<Route path="/catalogvehiclebrands/index/:fieldName/:fieldValue" element={<CatalogvehiclebrandsList />} />
				<Route path="/catalogvehiclebrands/view/:pageid" element={<CatalogvehiclebrandsView />} />
				<Route path="/catalogvehiclebrands/add" element={<CatalogvehiclebrandsAdd />} />
				<Route path="/catalogvehiclebrands/edit/:pageid" element={<CatalogvehiclebrandsEdit />} />

				{/* catalogvehiclemodels pages routes */}
				<Route path="/catalogvehiclemodels" element={<CatalogvehiclemodelsList />} />
				<Route path="/catalogvehiclemodels/:fieldName/:fieldValue" element={<CatalogvehiclemodelsList />} />
				<Route path="/catalogvehiclemodels/index/:fieldName/:fieldValue" element={<CatalogvehiclemodelsList />} />
				<Route path="/catalogvehiclemodels/view/:pageid" element={<CatalogvehiclemodelsView />} />
				<Route path="/catalogvehiclemodels/add" element={<CatalogvehiclemodelsAdd />} />
				<Route path="/catalogvehiclemodels/edit/:pageid" element={<CatalogvehiclemodelsEdit />} />

				{/* catalogvehiclemotortypes pages routes */}
				<Route path="/catalogvehiclemotortypes" element={<CatalogvehiclemotortypesList />} />
				<Route path="/catalogvehiclemotortypes/:fieldName/:fieldValue" element={<CatalogvehiclemotortypesList />} />
				<Route path="/catalogvehiclemotortypes/index/:fieldName/:fieldValue" element={<CatalogvehiclemotortypesList />} />
				<Route path="/catalogvehiclemotortypes/view/:pageid" element={<CatalogvehiclemotortypesView />} />
				<Route path="/catalogvehiclemotortypes/add" element={<CatalogvehiclemotortypesAdd />} />
				<Route path="/catalogvehiclemotortypes/edit/:pageid" element={<CatalogvehiclemotortypesEdit />} />

				{/* catalogvehicleplatestates pages routes */}
				<Route path="/catalogvehicleplatestates" element={<CatalogvehicleplatestatesList />} />
				<Route path="/catalogvehicleplatestates/:fieldName/:fieldValue" element={<CatalogvehicleplatestatesList />} />
				<Route path="/catalogvehicleplatestates/index/:fieldName/:fieldValue" element={<CatalogvehicleplatestatesList />} />
				<Route path="/catalogvehicleplatestates/view/:pageid" element={<CatalogvehicleplatestatesView />} />
				<Route path="/catalogvehicleplatestates/add" element={<CatalogvehicleplatestatesAdd />} />
				<Route path="/catalogvehicleplatestates/edit/:pageid" element={<CatalogvehicleplatestatesEdit />} />

				{/* catalogvehicletypes pages routes */}
				<Route path="/catalogvehicletypes" element={<CatalogvehicletypesList />} />
				<Route path="/catalogvehicletypes/:fieldName/:fieldValue" element={<CatalogvehicletypesList />} />
				<Route path="/catalogvehicletypes/index/:fieldName/:fieldValue" element={<CatalogvehicletypesList />} />
				<Route path="/catalogvehicletypes/view/:pageid" element={<CatalogvehicletypesView />} />
				<Route path="/catalogvehicletypes/add" element={<CatalogvehicletypesAdd />} />
				<Route path="/catalogvehicletypes/edit/:pageid" element={<CatalogvehicletypesEdit />} />

				
				{/* reportes inventarios */}
				<Route path="/kardex" element={<ReporteKardex />} />
				<Route path="/kardex/:fieldName/:fieldValue" element={<ReporteKardex />} />
				<Route path="/kardex/index/:fieldName/:fieldValue" element={<ReporteKardex />} />
				
				{/* infraction pages routes */}
				<Route path="/infraction" element={<InfractionList />} />
				<Route path="/infraction/:fieldName/:fieldValue" element={<InfractionList />} />
				<Route path="/infraction/index/:fieldName/:fieldValue" element={<InfractionList />} />
				<Route path="/infraction/view/:pageid" element={<InfractionView />} />
				<Route path="/infraction/edit/:pageid" element={<InfractionEdit />} />
				<Route path="/infraction/Recepcion/:pageid" element={<InfractionRecepcion />} />
				<Route path="/infraction/Entregar/:pageid" element={<InfractionEntregar />} />
				

				{/* infractiondetail pages routes */}
				<Route path="/infractiondetail" element={<InfractiondetailList />} />
				<Route path="/infractiondetail/:fieldName/:fieldValue" element={<InfractiondetailList />} />
				<Route path="/infractiondetail/index/:fieldName/:fieldValue" element={<InfractiondetailList />} />
				<Route path="/infractiondetail/view/:pageid" element={<InfractiondetailView />} />
				<Route path="/infractiondetail/add" element={<InfractiondetailAdd />} />
				<Route path="/infractiondetail/edit/:pageid" element={<InfractiondetailEdit />} />
				<Route path="/infractiondetail/innerdetaillist/" element={<InfractiondetailInnerdetaillist />} />
				<Route path="/infractiondetail/innerdetaillist/:fieldName/:fieldValue" element={<InfractiondetailInnerdetaillist />} />
				


				<Route path="/infractiondetaildocument" element={<InfractiondetailDocumentList />} />
				<Route path="/infractiondetaildocument/:fieldName/:fieldValue" element={<InfractiondetailDocumentList />} />
				<Route path="/infractiondetaildocument/index/:fieldName/:fieldValue" element={<InfractiondetailDocumentList />} />
				<Route path="/infractiondetaildocument/view/:pageid" element={<InfractiondetailDocumentView />} />
				<Route path="/infractiondetaildocument/add" element={<InfractiondetailDocumentAdd />} />
				<Route path="/infractiondetaildocument/edit/:pageid" element={<InfractiondetailDocumentEdit />} />
				<Route path="/infractiondetaildocument/innerdetaillistdocumentos/" element={<InfractiondetailInnerdetaillistDocument />} />
				<Route path="/infractiondetaildocument/innerdetaillistdocumentos/:fieldName/:fieldValue" element={<InfractiondetailInnerdetaillistDocument />} />

				{/* users pages routes */}
				<Route path="/users" element={<UsersList />} />
				<Route path="/users/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/index/:fieldName/:fieldValue" element={<UsersList />} />
				<Route path="/users/view/:pageid" element={<UsersView />} />
				<Route path="/users/add" element={<UsersAdd />} />
				<Route path="/account/*" element={<AccountPages />} />
				<Route path="/users/edit/:pageid" element={<UsersEdit />} />
				{/* users pages routes */}
				<Route path="/uma" element={<UmaList />} />
				<Route path="/uma/:fieldName/:fieldValue" element={<UmaList />} />
				<Route path="/uma/index/:fieldName/:fieldValue" element={<UmaList />} />
				<Route path="/uma/view/:pageid" element={<UmaView />} />
				<Route path="/uma/add" element={<UmaAdd />} />

				<Route path="/uma/edit/:pageid" element={<UmaEdit />} />


			</Route>
			</Route>
			<Route exact element={<IndexLayout />}>
				<Route path="/" element={<DefaultPage />} />
				<Route path="/*" element={<IndexPages />} />
				<Route path="/error/*" element={<ErrorPages />} />
			</Route>
		</Routes>
	);
}
export default App;
