import { useState } from 'react';
import { Chart } from 'primereact/chart';
import { DataSource } from 'components/DataSource';
import { RecordCount } from 'components/RecordCount';
import { Title } from 'components/Title';

export default function HomePage() {
	
	const [pageReady, setPageReady] = useState(true);
	return (
		<main id="HomePage" className="main-page">
          
                
		</main>
	);
}
