import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';

import { Dropdown } from 'primereact/dropdown';
import useEditPage from 'hooks/useEditPage';

import React, { useState, useEffect } from 'react';

const CatalogusertypesEditPage = (props) => {
		const app = useApp();
	// form validation schema
	const validationSchema = yup.object().shape({
		amount: yup.string().nullable().label("amount"),
		year: yup.string().nullable().label("year")
	});
	// form default values
	const formDefaultValues = {
		amount: "NULL",
		year: "NULL"
	}

	const [selectedCity, setSelectedCity] = useState(null);
    const [selectedEstado, setselectedEstado] = useState(null);
    const [selectedLocalidad, setselectedLocalidad] = useState(null);


    const Estado = [
        { name: 'Activo', code: 1 },
         { name: 'Inactivo', code: 0 }         
     ];
     const Localidad = [
        { name: 'SAN JOSE', code: 1 },
         { name: 'SAN LUCAS', code: 2 }         
     ];
	
	
	const cities = [
       { name: 'Cajera/Agente', code: 3 },
        { name: 'General', code: 2 },
        { name: 'Administrador', code: 1 }
    ];

    
    
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	 const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	
     //Event raised on form submit success
	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigate(`/users`);
		}
	}
	var rol=[];
	rol[1]="Administrador";
	rol[2]="General";
	rol[3]="Cajera/Agente";
	 
   function defaulSelect(array,valor,setselect){
        console.log("valor defaul",valor);
        console.log(selectedCity);
        let  xvalue=array.map(function(ele){
                    console.log(ele);
                    return ele.code
            }).indexOf(valor);
            if(xvalue!=-1){
                xvalue=array[xvalue];
            }
            if(setselect==1){
                if(selectedCity===null){
                    setSelectedCity(xvalue);
                }
            }else if(setselect==2){
                if(selectedEstado===null){
                    setselectedEstado(xvalue);
                }
            }else if(setselect==3){
                if(selectedLocalidad===null){
                    setselectedLocalidad(xvalue);
                }
            } 
            return false;
   }

    
    
	// loading form data from api
	if(loading){
        
        
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
       


		return (
<main id="CatalogusertypesEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik 
                        initialValues={{
                            id:formData.id,
                            prefix_code: formData.prefix_code,
                            username:formData.username,
                            password: formData.password,
                            rol: formData.rol,
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            location_id:formData.location_id,
                            status:formData.status
                        }
                        }
                        validationSchema={validationSchema} 
                        onSubmit={(values, actions) =>{
                        	if(selectedCity!=null){

                        	values.rol=selectedCity.code;	
                            values.location_id=selectedLocalidad.code
                                if(selectedEstado.code>=0){
                                    values.status=selectedEstado.code;
                                }
                            
                        	}
                        	submitForm(values);
                        }
                        }>
                            {(formik) => 
                            <>
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                               	Serie 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="prefix_code"  onChange={formik.handleChange}  value={formik.values.prefix_code}   label="prefix_code" type="text" placeholder="Serie" className={inputClassName(formik?.errors?.prefix_code)} />
                                                <ErrorMessage name="prefix_code" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Nombre 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="firstname"  onChange={formik.handleChange}  value={formik.values.firstname}   label="firstname" type="text" placeholder="Nombre"    className={inputClassName(formik?.errors?.firstname)} />
                                                <ErrorMessage name="firstname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Apellido 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="lastname"  onChange={formik.handleChange}  value={formik.values.lastname}   label="lastname" type="text" placeholder="Apellido"    className={inputClassName(formik?.errors?.lastname)} />
                                                <ErrorMessage name="lastname" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Usuario 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="username"  onChange={formik.handleChange}  value={formik.values.username}   label="username" type="text" placeholder="usuario"    className={inputClassName(formik?.errors?.username)} />
                                                <ErrorMessage name="username" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Clave 
                                            </div>
                                            <div className="col-12 md:col-9">
                                                <InputText name="password"  onChange={formik.handleChange}  value={formik.values.password}   label="password" type="password" placeholder="Contraseña"    className={inputClassName(formik?.errors?.password)} />
                                                <ErrorMessage name="password" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                   
                                     <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Rol del usuario
                                            </div>
                                            <div className="col-12 md:col-9">
                                            		<div>{rol[formData.rol]}</div>	
										            <Dropdown
										             value={selectedCity} 
                                                     defaultValue={defaulSelect(cities,formData.rol,1)}
										             onChange={(e) => {
                                                        console.log(e.value)
                                                        setSelectedCity(e.value)
                                                    }} 
										             options={cities} 
										             optionLabel="name" 
										             placeholder="cambiar Rol" 
										             className="w-full md:w-14rem" />
										        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Ubicaciòn
                                            </div>
                                            <div className="col-12 md:col-9">
                                            		<div>{rol[formData.location_name]}</div>	
										            <Dropdown
										             value={selectedLocalidad} 
										             defaultValue={defaulSelect(Localidad,formData.location_id,3)}
										             options={Localidad} 
                                                     onChange={(e) => {
                                                        setselectedLocalidad(e.value);
                                                        formik.setFieldValue('location_id', e.value.code);
                                                    }}
										             optionLabel="name" 
										             placeholder="Cambiar Localidad" 
										             className="w-full md:w-14rem" />
										        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Estado
                                            </div>
                                            <div className="col-12 md:col-9">
                                            		<div>{rol[formData.rol]}</div>	
										            <Dropdown
										               value={selectedEstado}
                                                       
										             defaultValue={defaulSelect(Estado,formData.status,2)}
                                                       onChange={(e) => {
                                                        setselectedEstado(e.value);
                                                        formik.setFieldValue('status', e.value.code);
                                                    }}
										             options={Estado} 
										             optionLabel="name" 
										             placeholder="cambiar Estado" 
										             className="w-full md:w-14rem" />
										        
                                            </div>
                                        </div>
                                    </div>
                                     
                                   
                                    
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)} className="p-button-primary" type="submit" label="Guardar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            </>
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    
		);
        
	}
}
CatalogusertypesEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'users',
	apiPath: 'users/edit',
	routeName: 'usersedit',
	submitButtonLabel: "Actualizar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Actualizar registro",
	msgAfterSave: "Registro actualizado con éxito",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default CatalogusertypesEditPage;
