import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataSource } from 'components/DataSource';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useListPage from 'hooks/useListPage';
import { Dropdown } from 'primereact/dropdown';
import React,{ useState, useEffect,useRef } from 'react';
import useApi from 'hooks/useApi';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import useAuth from 'hooks/useAuth';

const ReporteKardexPage = (props) => {

		const app = useApp();
		
        const api = useApi();
	const filterSchema = {
		search: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		},
		date: {
			tagTitle: "Date",
			value: [],
			valueType: 'range-date',
			options: [],
		},
		fieldname:{
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		},
		fieldvalue: {
			tagTitle: "Search",
			value: '',
			valueType: 'single',
			options: [],
		}
	}

	const auth = useAuth();

        var ViewAuth='none';
        if(auth.userRol!=3){
            ViewAuth='inline-block';
        }



	
	const pageController = useListPage(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const { filters, setFilterValue, setFilterOptions } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;
	function ActionButton(data){

		var items = [
		{
			label: "Ver",
			command: (event) => { app.navigate(`/infraction/view/${data.id}`) },
			icon: "pi pi-eye"
		},
		{
			label: "Editar",
			command: (event) => { app.navigate(`/infraction/edit/${data.id}`) },
			icon: "pi pi-pencil"
		},
		
					{
						label: "Recepción",
						command: (event) => { app.navigate(`/infraction/recepcion/${data.id}`) },
						icon: "pi pi-pencil"
					},
					{
						label: "Entregar",
						command: (event) => { app.navigate(`/infraction/entregar/${data.id}`) },
						icon: "pi pi-pencil"
					},
		{
			label: "Borrar",
			command: (event) => { 
				  confirmDialog({
		                    message: 'Estas seguro de ** BORRAR **?',
		                    header: 'Cancelación',
		                    icon: 'pi pi-exclamation-triangle',
		                    accept:()=>{ 
		                    	if(auth.userRol!=3){
					                           api.post('/Infraction/Delete',
					                                                      {
					                                                        id: parseInt(data.id) 
					                                                        
					                                                        }).then((res) => {
					                                                                console.log(res);
					                                                                if(res.data.status=='Ok'){
					                                                                     app.flashMsg('', res.data.message, "success");
					                                                                    app.navigate(0);
					                                                                   
					                                                                }else{
					                                                                    app.flashMsg('', res.data.message, "error");
					                                                            }

					                                                    });
					                }

		                    },
		                    reject
		                });//deleteItem(data.id) 
			},
			icon: "pi pi-trash"
		}
		/*
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}*/
	]
	if(auth.userRol==3){
			 items = [
					{
						label: "Ver",
						command: (event) => { 
							app.navigate(`/infraction/view/${data.id}`) 
						},
						icon: "pi pi-eye"
					},
					{
						label: "Recepción",
						command: (event) => { app.navigate(`/infraction/recepcion/${data.id}`) },
						icon: "pi pi-pencil"
					},
					{
						label: "Entregar",
						command: (event) => { app.navigate(`/infraction/entregar/${data.id}`) },
						icon: "pi pi-pencil"
					}
			]
		}


		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	const reject = () => {
                //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
            }
	

	
	function UsernameTemplate(data){
		if(data){
			return (
				<>{ data.users_username }</>
			);
		}
	}



const [selectedLocalidad, setselectedLocalidad] = useState(null);

function defaulSelect(array,valor,setselect){
    
    let  xvalue=array.map(function(ele){
                
                return ele.code
        }).indexOf(valor);
        if(xvalue!=-1){
            xvalue=array[xvalue];
			console.log("permiso "+auth.user.rol);
			
            if(selectedLocalidad===null){
				
                setselectedLocalidad(xvalue);
				
            }
        }
		
        
        return false;
}


var Localidad = [
    { name: 'SAN LUCAS', code: 2 },
    { name: 'SAN JOSE', code: 1 }	
              
 ];
if(auth.user.rol==3){
	let  xvalue=Localidad.map(function(ele){			
			return ele.code
		}).indexOf(auth.user.location_id);
		if(xvalue!=-1){
			xvalue=Localidad[xvalue];
			Localidad=[xvalue];
		}

		console.log(Localidad);
}

	 

	
	function IsAccidentTemplate(data){
		if(data){
			return (
					<div>
		{data.is_accident == '1' ? (
       	<Button icon="pi pi-check-circle" className="p-button-sm p-button-success" tooltip={data.is_accident} />
      ) : (
        <Button icon="pi pi-ban" className="p-button-sm p-button-secondary" tooltip={data.is_accident} />
      )}
	</div>
			);
		}
	}

	function TimeDate(data){
		var dd=data.date;
		dd=dd.split('T');
		return (dd[0]+' '+dd[1])
	}
	function StatusTemplate(data){
		if(data){
			return (
					<div>
		{data.status == '2' ? (
       	<Button icon="pi pi-check-circle" className="p-button-sm p-button-success" tooltip={data.status} />
      ) : (data.status =='1'?(
        <Button icon="pi pi-ban" className="p-button-sm p-button-warning" tooltip={data.status} />
      ):(
        <Button icon="pi pi-trash" className="p-button-sm p-button-danger" tooltip={data.status} />
      ))}
	</div>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length&&auth.userRol!=3) {
			return (
				<div className="m-2 flex-grow-0">
					<Button onClick={() => deleteItem(selectedItems)} icon="pi pi-trash" className="p-button-danger" title="Eliminar seleccionado"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="InfractionListPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title="Kardex de Documentos Retenidos"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500" iconClass="pi pi-file" avatarSize="large"    separator={false} />
                </div>
                <div className="col-fixed " >
                   
                    </div>
                    
                    <div className="col-12 md:col-3 " >
                        <span className="p-input-icon-left w-full">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscar" className="w-full" value={filters.search.value}  onChange={(e) => setFilterValue('search', e.target.value)} />
                        </span>
                    </div>
					<div className="col-12 md:col-3 " >
                       						
										            <Dropdown
										             value={selectedLocalidad} 
										             defaultValue={defaulSelect(Localidad,auth.user.location_id,3)}
										             options={Localidad} 
                                                     onChange={(e) => {
                                                        setselectedLocalidad(e.value);
														setFilterValue('fieldvalue', e.value.name);
														setFilterValue('fieldname', 'location_name');
                                                    }}
										             optionLabel="name" 
										             placeholder="Cambiar Localidad" 
										             className="w-full md:w-14rem" />
                    </div>
                </div>
            </div>
            
        </section>
        }
       
        <section className="page-section " >
            <div className="container-fluid">
                <div className="grid ">
                    <div className="col comp-grid" >
                        <FilterTags filterController={filterController} />
                        <div >
                            <PageBreadcrumbs />
                            <div className="page-records">
                                <DataTable 
                                    lazy={true} 
                                    loading={loading} 
                                    selectionMode="checkbox" selection={selectedItems} onSelectionChange={e => setSelectedItems(e.value)}
                                    value={records} 
                                    dataKey="id" 
                                    sortField="date" 
                                    sortOrder={-1} 
                                    className=" p-datatable-sm" 
                                    stripedRows={true}
                                    showGridlines={false} 
                                    rowHover={true} 
                                    responsiveLayout="stack" 
                                    emptyMessage={<EmptyRecordMessage />} 
                                    >
                                    {/*PageComponentStart*/}                                
                                     <Column  field="serie" header="Serie"   ></Column>
                                    <Column  field="folio" header="Folio"   ></Column>            
									<Column  field="document_type" header="Docuemnto"   ></Column>            

                                    <Column  field="driver_firstname" header="Nombre (s)"   ></Column>
                                    <Column  field="driver_lastname" header="Apellido(s) "   ></Column>                                                                                                            
                                    <Column  field="agent" header="Agente Nombre"  ></Column>                                    
                                     <Column  field="location_name" header="Ubicaciòn"   ></Column>
									 <Column  field="user_in_name" header="Usuario Recibe"   ></Column>
									 <Column  field="date_in" header="Fecha Entrada"   ></Column>
									 <Column  field="user_out_name" header="usuario Entrega"  ></Column>                                                                        
									 <Column  field="date_out" header="Fecha Salida"  ></Column>   									                									                                     
                                    {/*PageComponentEnd*/}
                                </DataTable>
                            </div>
                            <PageFooter />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
	);
}
ReporteKardexPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'Kardex Doc. Retenidos',
	apiPath: 'RetainedDocuments/report',
	routeName: 'infractionlist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: "Eliminar el registro",
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default ReporteKardexPage;
