import axioss from 'axios';
import useLocalStore from './useLocalStore';
function useApiAux() {
    const localStore = useLocalStore();
    // If token exists set header
    let token = localStore.getToken();
    if (token) {
       // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }

    return {
        removeHeader() {
          //  axios.defaults.headers.common = {}
        },
        get(apiPath) {
            return axioss.get(apiPath)
        },
      
        

        download(apiPath) {
            return axioss({
                url: apiPath,
                method: 'GET',
                responseType: 'blob', // important
            })
        },
        post(apiPath, data) {
            return axioss({
                method: 'post',
                url: apiPath,
                data:data,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': 'application/json',
                }
            });
            
        },

        put(apiPath, formData) {
            return axioss.put(apiPath, formData)
        },

        delete(apiPath) {
            return axioss.delete(apiPath)
        },
        customRequest(data) {
            return axioss(data)
        },
        axios() {
            return axioss;
        },
    }
}
export default useApiAux;