import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { PageRequestError } from 'components/PageRequestError';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Title } from 'components/Title';
import useApp from 'hooks/useApp';
import useEditPage from 'hooks/useEditPage';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from 'react';

import { MultiSelect } from 'hooks/extra';

const InfractiondetailEditPage = (props) => {
		const app = useApp();
	// form validation schema

	const multiservicio = new MultiSelect();
	const { pageid } = useParams();
	const validationSchema = yup.object().shape({
		fk_infraction: yup.number().nullable().label("Fk Infraction"),
		fk_item: yup.number().nullable().label("Fk Item")
	});
	// form default values
	const formDefaultValues = {
		fk_infraction: "NULL", 
		fk_item: "NULL", 
	}
	//where page logics resides
	const pageController = useEditPage({ props, formDefaultValues, afterSubmit });
	//destructure and grab what we need
	const { formData, handleSubmit, submitForm, pageReady, loading, saving, apiRequestError, inputClassName } = pageController
	//Event raised on form submit success




	/******SACAMOS LA LISTA DE UUID DE DEVICE *******/

		var devicearray=[];//CREAMOS ARREGLO DONDE GUARDAMOS LA LISTA DEL API
		const [device, setDevice] = useState(null);//CREAMOS SET Y VARIABLE PARA CONTENER LA LISTA DE LA API
		const [selectdevice, setSelectedDevice] = useState(null);//CREAMOS VARIABLES PARA EL SELECT
		const [selectdevicehi, setSelectedDevicehi] = useState(null);
		 const onDeviceChange = (e) => {//CHANGE DEL SELEECT
	        setSelectedDevice(e.value);
	        setSelectedDevicehi(e.value);
	       // formik.values.fk_item=e.value;
	        document.getElementById('fk_item').value=e.value;
	        document.getElementById('fk_item').focus();
	    }

		useEffect(() => {//MANDAMOS LLAMAR LA API DE GETDEVICE
	             multiservicio.getConceptosInfraccion().then(data => setDevice(data));
	       }, []);

		if(device){
		 	devicearray=multiservicio.getArraySelectConceptosInfraccion(device.records);
		}
	
	/******FIN *******/
		//setSelectedDevice(formData.fk_item);


	function afterSubmit(response){
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			//app.navigate(`infraction/view/`+formData.fk_infraction);
		}
	}
	// loading form data from api
	if(loading){
		return (
			<div className="p-3 text-center">
				<ProgressSpinner style={{width:'50px', height:'50px'}} />
			</div>
		);
	}
	//display error page 
	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	//page is ready when formdata loaded successfully
	if(pageReady){
		return (
<main id="InfractiondetailEditPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container">
            <div className="grid justify-content-between align-items-center">
                { !props.isSubPage && 
                <div className="col-fixed " >
                    <Button onClick={() => app.navigate(-1)} label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                </div>
                }
                <div className="col " >
                    <Title title="Editar"   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
            </div>
        </div>
    </section>
    }
    <section className="page-section " >
        <div className="container">
            <div className="grid ">
                <div className="md:col-9 sm:col-12 comp-grid" >
                    <div >
                        <Formik
                            initialValues={{id:formData.id,fk_item:formData.fk_item,fk_infraction:formData.fk_infraction }}
                            
                            onSubmit={(values, actions) => {
                            	//console.log(values);
                            	values.fk_item=selectdevice;
	                            submitForm(values);
	                            }
                            }
                            >
                            { (formik) => {
                            return (
                            <Form className={`${!props.isSubPage ? 'card  ' : ''}`}>
                                <div className="grid">
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Folio 
                                            </div>
                                            <div className="col-12 md:col-9">
                                            	{formData.fk_infraction}
                                                <InputText name="fk_infraction"  onChange={formik.handleChange}  value={formData.fk_infraction}   label="Fk Infraction" type="hidden" placeholder="Escribir Fk Infraction"  min={0}  step="any"    className={inputClassName(formik?.errors?.fk_infraction)} />
                                                <ErrorMessage name="fk_infraction" component="span" className="p-error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="formgrid grid">
                                            <div className="col-12 md:col-3">
                                                Infracción
                                                
                                                  <InputText id="fk_item" name="fk_item" onBlur={formik.handleBlur} onChange={formik.handleChange}   value={selectdevicehi} label="fk_item" type="hidden"  className={inputClassName(formik?.errors?.fk_item)} />
                                                <ErrorMessage name="fk_item" component="span" className="p-error" />
                                            </div>
                                            <div className="col-12 md:col-9">
	                                            <Dropdown 
	                                            	id="fk_item2"
	                                            	filter
	                                            	optionLabel="name" 
	                                            	optionValue="value" 
	                                            	value={selectdevice} 
	                                            	options={devicearray} 
	                                            	onChange={onDeviceChange} 
	                                            	placeholder="Selecionar para cambiar de infración" 
	                                            	className={inputClassName(formik?.errors?.fk_item)} 
	                                            	/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { props.showFooter && 
                                <div className="text-center my-3">
                                    <Button onClick={(e) => handleSubmit(e, formik)}  type="submit" label="Actualizar" icon="pi pi-send" loading={saving} />
                                </div>
                                }
                            </Form>
                            );
                            }
                            }
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
		);
	}

}
InfractiondetailEditPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'infractiondetail',
	apiPath: 'infractiondetail/edit',
	routeName: 'infractiondetailedit',
	submitButtonLabel: "Actualizar",
	formValidationError: "El formulario no es válido",
	formValidationMsg: "Por favor complete el formulario",
	msgTitle: "Actualizar registro",
	msgAfterSave: "Registro actualizado con éxito",
	msgBeforeSave: "",
	showHeader: true,
	showFooter: true,
	redirect: true,
	isSubPage: false
}
export default InfractiondetailEditPage;
