import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterTags } from 'components/FilterTags';
import { PageRequestError } from 'components/PageRequestError';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Title } from 'components/Title';
import InfractiondetailAddPage from 'pages/infractiondetail/Add';

import React,{ useState, useEffect,useRef } from 'react';
import useApp from 'hooks/useApp';
import { ImageViewer } from 'components/ImageViewer';
import useListId from 'hooks/useListId';
import useApi from 'hooks/useApi';
import { Image } from 'primereact/image';
import useAuth from 'hooks/useAuth';

import { Dropdown } from 'primereact/dropdown';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const DetalleRecepcionPage = (props) => {
		const app = useApp();
		const api = useApi();
		
	const filterSchema = {
	}
	const pageController = useListId(props, filterSchema);
	const filterController = pageController.filterController;
	const { records, pageReady, loading, selectedItems, sortBy, sortOrder, apiRequestError, setSelectedItems, getPageBreadCrumbs, onSort, deleteItem, pagination } = pageController;
	const {  } = filterController;
	const { totalRecords, totalPages, recordsPosition, firstRow, limit, onPageChange } =  pagination;

	 const auth = useAuth();

        var ViewAuth='none';
        if(auth.userRol!=3){
            ViewAuth='inline-block';
        }
       // ViewAuth='inline-block';
	

	function ActionButton(data){
		var items = [
		
		{
			label: "Edit",
			command: (event) => { app.navigate(`/infractiondetaildocument/edit/${data.id}`) },
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.id) },
			icon: "pi pi-trash"
		}
	]
		 if(auth.userRol==3){
            items=[];
        }
	
		return (<SplitButton dropdownIcon="pi pi-bars" className="dropdown-only p-button-text p-button-plain" model={items} />);
	}
	function IdTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_id }</>
			);
		}
	}

	const [selectedLocalidad, setselectedLocalidad] = useState(null);

 
 function defaulSelect(array,valor,setselect){
    
    let  xvalue=array.map(function(ele){
                
                return ele.code
        }).indexOf(valor);
        if(xvalue!=-1){
            xvalue=array[xvalue];
			console.log("permiso "+auth.user.rol);
			
            if(selectedLocalidad===null){
				
                setselectedLocalidad(xvalue);
				
            }
        }
		
        
        return false;
}


var Localidad = [
    { name: 'SAN LUCAS', code: 2 },
    { name: 'SAN JOSE', code: 1 }
              
 ];
if(auth.user.rol==3){
	let  xvalue=Localidad.map(function(ele){			
			return ele.code
		}).indexOf(auth.user.location_id);
		if(xvalue!=-1){
			xvalue=Localidad[xvalue];
			Localidad=[xvalue];
		}

		console.log(Localidad);
}


function DocRetenido(data){
		if(data){
			return (
				  <Image  previewSize="" src={data.photo} height="150" preview />
			);
		}
	}

	function CodeTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_code }</>
			);
		}
	}
	function DescriptionTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_description }</>
			);
		}
	}
	function DsmTemplate(data){
		if(data){
			return (
				<>{ data.cataloginfractionitems_dsm }</>
			);
		}
	}
	
	function TotalFormat(data){
		if(data){
			return (
				<>${ parseFloat(data.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') }</>
			);
		}
	}
	function PageLoading(){
		if(loading){
			return (
				<>
					<div className="flex align-items-center justify-content-center text-gray-500 p-3">
						<div><ProgressSpinner style={{width:'30px', height:'30px'}} /> </div>
						<div  className="font-bold text-lg">Cargando...</div>
					</div>
				</>
			);
		}
	}
	function EmptyRecordMessage(){
		if(pageReady && !records.length){
			return (
				<div className="text-lg mt-3 p-3 text-center text-400 font-bold">
					ningún record fue encontrado
				</div>
			);
		}
	}
	function MultiDelete() {
		if (selectedItems.length) {
			return (
				<div className="col-12 text-right">
					<Button onClick={() => ConfirmCmbiarNombre(selectedItems)} icon="pi pi-save" label='Guardar Inventario' className="p-button-success" title="Guardar"/>
				</div>
			)
		}
	}
	function PagerControl() {
		if (props.paginate && totalPages > 1) {
		const pagerReportTemplate = {
			layout: pagination.layout,
			CurrentPageReport: (options) => {
				return (
					<>
						<span className="text-sm text-gray-500 px-2">Archivos <b>{ recordsPosition } de { options.totalRecords }</b></span>
					</>
				);
			}
		}
		return (
			<div className="flex-grow-1">
				<Paginator first={firstRow} rows={limit} totalRecords={totalRecords}  onPageChange={onPageChange} template={pagerReportTemplate} />
			</div>
		)
		}
	}
	function PageActionButtons() {
		return (
			<div className="flex flex-wrap">
				<MultiDelete />
			</div>
		);
	}
	function PageFooter() {
		if (pageReady && props.showFooter) {
			return (
				<div className="flex flex-wrap">
					<PageActionButtons />
					<PagerControl />
				</div>
			);
		}
	}
	function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}

    function PageBreadcrumbs(){
		if(props.showBreadcrumbs) {
			const items = getPageBreadCrumbs();
			return (items.length > 0 && <BreadCrumb className="mb-3" model={items} />);
		}
	}
	const reject = () => {
		//toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
	}

	function ConfirmCmbiarNombre(item){
			if (selectedItems.length) {
				confirmDialog({
					message: 'Estas seguro recibir la documentaciòn selecionada?',
					header: 'Recibir',
					icon: 'pi pi-exclamation-triangle',
					accept: () => RecibirDocumentacion(item),
					reject
				});
			}
	};
    async function RecibirDocumentacion(item){
		
		for(let i=0;item.length>i;i++){
			await api.post('/RetainedDocuments/Receive',
					{
						document_id: item[i].id,
					location_id: selectedLocalidad.code,
					user_id_in:auth.userId
					}).then((res) => {
							console.log(res);
				});
		}

		app.navigate(0) ;
		
		
		 
		
	}
	function isRowSelectable(dto){
		//console.log(dto);
		//(auth.user.location_id==dto.data.location_id?true:false)
		return  (auth.user.rol!=3?true:(dto.data.location_id===null?true:(auth.user.location_id==dto.data.location_id?true:false)));
	}
	function rowClassName(dto) {
		console.log(dto);
		return  (auth.user.rol!=3?'':(dto.location_id===null?'':(auth.user.location_id==dto.location_id?'':'p-disabled')));
	}
		//(isSelectable(data) ? '' : 'p-disabled');
	function EntregarDocumentacion(item){
		console.log(item);
		api.post('/RetainedDocuments/Deliver',
		{
			document_id: item[0].id,
		  location_id: auth.user.location_id,
		  user_id_in:auth.userId
		  }).then((res) => {
				  console.log(res);
				  if(res.data.status=='Ok'){
					   app.flashMsg('', 'Infracción Cancelada', "success");
					   // printWebCancelacion(item,dataDtalle,dataDtalleDoc);
					 
					 
				  }else{
					  app.flashMsg('', res.data.message, "error");
			  }

	  });
		
	}

	if(apiRequestError){
		return (
			<PageRequestError error={apiRequestError} />
		);
	}
	return (
<main id="InfractiondetailInnerdetaillistPage" className="main-page">
    { (props.showHeader) && 
    <section className="page-section mb-3" >
        <div className="container-fluid">
            <div className="grid justify-content-between align-items-center">
                <div className="col " >
                    <Title title=" "   titleClass="text-2xl text-primary font-bold" subTitleClass="text-500"      separator={false} />
                </div>
                <div className="col-fixed " style={{display:ViewAuth}} >
                    <Button label="Agregar Documento" icon="pi pi-plus"  onClick={()=>app.navigate('/infractiondetaildocument/add')}  className="p-button w-full bg-primary "  />
                </div>
                <div className="col-12 md:col-3 " >
                </div>
            </div>
        </div>
    </section>
    }
	<div className="col-12 text-right">
                                        <div className="formgrid grid">
                                            <div className="col-8 md:col-8">
                                               
                                            </div>
                                            <div className="col-4 md:col-4 text-center">
                                            		<div ><b>Ubicación:</b></div>	
										            <Dropdown
										             value={selectedLocalidad} 
										             defaultValue={defaulSelect(Localidad,auth.user.location_id,3)}
										             options={Localidad} 
                                                     onChange={(e) => {
                                                        setselectedLocalidad(e.value);
                                                    }}
										             optionLabel="name" 
										             placeholder="Cambiar Localidad" 
										             className="w-full md:w-14rem" />
										        
                                            </div>
                                        </div>
                                    </div>
    <section className="page-section " >
        <div className="container-fluid">
            <div className="grid ">
                <div className="col comp-grid" >
                    <FilterTags filterController={filterController} />
                    <div >
                        <PageBreadcrumbs />
                        <div className="page-records">
                            <DataTable 
                                lazy={true} 
                                loading={loading} 
								isDataSelectable={isRowSelectable}
                                selectionMode="checkbox" 
								selection={selectedItems} 
								onSelectionChange={e => setSelectedItems(e.value)}
								rowClassName={rowClassName}
                                value={records} 
                                dataKey="id" 
                                sortField={sortBy} 
                                sortOrder={sortOrder} 
                                onSort={onSort}
                                className=" p-datatable-sm" 
                                stripedRows={true}
                                showGridlines={false} 
                                rowHover={true} 
                                responsiveLayout="stack" 
                                emptyMessage={<EmptyRecordMessage />} 
                                >
                                {/*PageComponentStart*/}
		                                <Column selectionMode="multiple" headerStyle={{width: '2rem'}}></Column>
		                                <Column  field="document_type" header="Descripción"  ></Column>
										<Column  field="location_name" header="Ubicaciòn"  ></Column>
										<Column  field="user_in_name" header="Usuario Recibe"  ></Column>
										<Column  field="user_out_name" header="Usuario Entrego"  ></Column>										
		                                <Column  field="photo" header="Evidencia" body={DocRetenido}  ></Column>
                                        <Column  field="notes" header="Nota"   ></Column>
                                {/*PageComponentEnd*/}
                            </DataTable>
                        </div>
                        <PageFooter />
                    </div>
                </div>
            </div>
        </div>
		
    </section>
</main>
	);
}
DetalleRecepcionPage.defaultProps = {
	primaryKey: 'id',
	pageName: 'RetainedDocuments',
	apiPath: 'RetainedDocuments/getRetainedDocumentsByInfraction',
	routeName: 'infractiondetailinnerdetaillist',
	msgBeforeDelete: "¿Seguro que quieres borrar este registro?",
	msgTitle: "Eliminar el registro",
	msgAfterDelete: "Grabar eliminado con éxito",
	showHeader: true,
	showFooter: true,
	paginate: true,
	isSubPage: false,
	showBreadcrumbs: true,
	exportData: false,
	importData: false,
	keepRecords: false,
	multiCheckbox: true,
	search: '',
	fieldName: null,
	fieldValue: null,
	sortField: '',
	sortDir: '',
	pageNo: 1,
	limit: 10,
}
export default DetalleRecepcionPage;
